import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';

const Home = React.lazy(() => import('./pages/Home'));
const CategoryPage = React.lazy(() => import('./pages/CategoryPage'));
const ProductPage = React.lazy(() => import('./pages/ProductPage'));
const CategoriesPage = React.lazy(() => import('./pages/CategoriesPage'));
const BlogPage = React.lazy(() => import('./pages/BlogPage'));
const BlogList = React.lazy(() => import('./pages/BlogList'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const ToS = React.lazy(() => import('./pages/ToS'));

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen bg-white">
    <div className="relative">
      <div className="w-12 h-12 rounded-full absolute
                      border-4 border-solid border-gray-200"></div>
      <div className="w-12 h-12 rounded-full animate-spin absolute
                      border-4 border-solid border-red-500 border-t-transparent"></div>
    </div>
  </div>
);

function App() {
  return (
    <Router>
      <Helmet>
        <title>Nutrifyx - Suplementos de Calidad para Todos</title>
        <meta name="description" content="Descubre los mejores suplementos deportivos, vitaminas y productos de salud en Nutrifyx." />
        <meta name="keywords" content="suplementos, proteínas, vitaminas, productos de salud, nutrifyx" />
        <meta property="og:title" content="Nutrifyx - Suplementos de Calidad para Todos" />
        <meta property="og:description" content="Descubre nuestra amplia gama de productos deportivos y de salud." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nutrifyx.shop" />
        <meta property="og:image" content="https://i.ibb.co/LdYsfZ0/Isotipo-Original-Nutrifyx.webp" />
      </Helmet>
      <Header />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:categoryName" element={<CategoryPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/category/:categoryName/:productName" element={<ProductPage />} />
          <Route path="/blog/:slug" element={<BlogPage />} />
          <Route path="/blog/" element={<BlogList />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/sobre-nosotros" element={<AboutUs />} />
          <Route path="/contacto" element={<ContactUs />} />
          <Route path="/terminos" element={<ToS />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;