import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, Menu, BookOpen, X, Dumbbell, Zap, Package, User, Mail } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Header: React.FC = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
                buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                closeDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const menuItems = [
        { name: 'Proteínas', icon: Dumbbell, link: '/category/proteinas' },
        { name: 'Creatinas', icon: Zap, link: '/category/creatinas' },
        { name: 'Combos', icon: Package, link: '/category/combos' },
        
    ];

    return (
        <header className="fixed top-0 left-0 w-full z-50 bg-white border-b border-gray-200">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/" className="flex items-center">
                            <img
                                src="https://i.ibb.co/tzDN8YC/Imagotipo-Horizontal-Original-Nutrifyx-min.png"
                                alt="Nutrifyx Logo"
                                className="h-8 w-auto sm:h-10"
                            />
                        </Link>
                    </div>

                    <div className="md:hidden">
                        <button
                            onClick={toggleMobileMenu}
                            className="text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            aria-label={mobileMenuOpen ? "Cerrar menú" : "Abrir menú"}
                        >
                            {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                        </button>
                    </div>

                    <nav className="hidden md:flex space-x-10">
                        <div className="relative">
                            <button
                                ref={buttonRef}
                                onClick={toggleDropdown}
                                className="text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                aria-expanded={dropdownOpen}
                                aria-haspopup="true"
                            >
                                <span>Suplementos</span>
                                <ChevronDown className={`ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-in-out duration-150 ${dropdownOpen ? 'transform rotate-180' : ''}`} />
                            </button>
                            <AnimatePresence>
                                {dropdownOpen && (
                                    <motion.div
                                        ref={dropdownRef}
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        transition={{ duration: 0.2 }}
                                        className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                    >
                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                {menuItems.map((item) => (
                                                    <Link
                                                        key={item.name}
                                                        to={item.link}
                                                        onClick={closeDropdown}
                                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                                    >
                                                        <item.icon className="flex-shrink-0 h-6 w-6 text-red-600" aria-hidden="true" />
                                                        <div className="ml-4">
                                                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                        <Link to="/blog" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Blog
                        </Link>
                        <Link to="/sobre-nosotros" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Sobre Nosotros
                        </Link>
                        <Link to="/contacto" className="text-base font-medium text-gray-500 hover:text-gray-900">
                            Contacto
                        </Link>
                    </nav>
                </div>
            </div>

            <AnimatePresence>
                {mobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="md:hidden"
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            {menuItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.link}
                                    className="text-gray-700 hover:bg-gray-50 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={toggleMobileMenu}
                                >
                                    <item.icon className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                                    {item.name}
                                </Link>
                            ))}
                            <Link
                                to="/blog"
                                className="text-gray-700 hover:bg-gray-50 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                                onClick={toggleMobileMenu}
                            >
                                <BookOpen className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                                Blog
                            </Link>
                            <Link
                                to="/sobre-nosotros"
                                className="text-gray-700 hover:bg-gray-50 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                                onClick={toggleMobileMenu}
                            >
                                <User className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                                Sobre Nosotros
                            </Link>
                            <Link
                                to="/contacto"
                                className="text-gray-700 hover:bg-gray-50 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
                                onClick={toggleMobileMenu}
                            >
                                <Mail className="inline-block mr-2 h-5 w-5" aria-hidden="true" />
                                Contacto
                            </Link>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </header>
    );
};

export default Header;