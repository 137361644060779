import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, MessageCircle } from 'lucide-react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-white border-t border-gray-200">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="space-y-8 xl:col-span-1">
                        <img
                            className="h-10"
                            src="https://i.ibb.co/LdYsfZ0/Isotipo-Original-Nutrifyx.webp"
                            alt="Nutrifyx Logo"
                        />
                        <p className="text-gray-500 text-base">
                            Suplementos de calidad para tu rendimiento óptimo.
                        </p>
                        <div className="flex space-x-6">
                            <a href="https://www.facebook.com/profile.php?id=61566533481374" className="text-gray-400 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <span className="sr-only">Facebook</span>
                                <Facebook className="h-6 w-6" />
                            </a>
                            <a href="https://www.instagram.com/nutrifyx.uy?igsh=MTd5cHlybTcxd29lZw==" className="text-gray-400 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <span className="sr-only">Instagram</span>
                                <Instagram className="h-6 w-6" />
                            </a>
                            <a href="https://wa.me/+59899123456" className="text-gray-400 hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <span className="sr-only">WhatsApp</span>
                                <MessageCircle className="h-6 w-6" />
                            </a>
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Productos
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to="/category/proteinas" className="text-base text-gray-500 hover:text-gray-900">
                                            Proteínas
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/category/creatinas" className="text-base text-gray-500 hover:text-gray-900">
                                            Creatinas
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/category/combos" className="text-base text-gray-500 hover:text-gray-900">
                                            Combos
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Soporte
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to="/sobre-nosotros" className="text-base text-gray-500 hover:text-gray-900">
                                            Sobre Nosotros
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contacto" className="text-base text-gray-500 hover:text-gray-900">
                                            Contacto
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Legal
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to="/terminos" className="text-base text-gray-500 hover:text-gray-900">
                                            Términos
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                    Blog
                                </h3>
                                <ul className="mt-4 space-y-4">
                                    <li>
                                        <Link to="/blog" className="text-base text-gray-500 hover:text-gray-900">
                                            Artículos
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-gray-400 xl:text-center">
                        &copy; 2024 Nutrifyx. Todos los derechos reservados
                    </p>
                </div>
                <div className="mt-4 text-center">
                    <p className="text-base text-gray-400">
                        Desarrollado por Gastón Torres
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;